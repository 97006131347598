import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Figure from "@yumgmbh/gatsby-theme-yum-components/src/components/molecules/Figure"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

import * as moment from "moment"
import useStoryblokDatasource from "../../../../hooks/useStoryblokDatasource"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"

const NewsPage = ({ content, name, language, breadcrumbs }) => {
  let seoImage = null

  const { storyblokDatasourceLookup } = useStoryblokDatasource()
  const mainCategory = storyblokDatasourceLookup(
    "categories",
    content?.categories?.[0]
  )

  if (
    !content.image?.filename &&
    content.body &&
    content.body[0] &&
    content.body[0].image &&
    content.body[0].image.filename
  ) {
    seoImage = content.body[0].image.filename
  } else {
    seoImage = content.image?.filename
  }

  let shortBreadcrumbs = [breadcrumbs[0]]

  return (
    <Layout language={language}>
      <div className="news">
        <SEO
          title={content.headline}
          content={content}
          og_image={seoImage}
          name={name}
        />
        <Breadcrumbs breadcrumbs={shortBreadcrumbs}></Breadcrumbs>
        <BlockWrapper block={{}} blockWidth="content" blockPadding="pt-8">
          <div>
            <span className="inline-block w-3 h-3 align-baseline bg-primary"></span>
            {content.display_date?.length > 0 ? (
              <span className="ml-4">
                {moment(content.display_date).format("DD.MM.YYYY")}
              </span>
            ) : (
              ""
            )}
            {content.kicker?.length > 0 ? (
              <span className="ml-4 font-bold">{mainCategory}</span>
            ) : (
              ""
            )}
          </div>
          <h1 className="mt-4 headline headline-h2">{content.title}</h1>
          <div className="mt-4 text-lg leading-loose text-gray-500 lg:leading-loose lg:text-xl">
            {content.teaser}
          </div>
        </BlockWrapper>
        {content.image?.filename?.length > 0 && (
          <BlockWrapper
            block={{}}
            blockPadding="pb-block"
            blockWidth="content"
            className=""
          >
            <Figure
              image={content.image}
              caption={content.image?.name}
              className="w-2/3 pt-8"
            />
            {content.text && (
              <Richtext text={content.text} className="text-lg leading-loose" />
            )}
          </BlockWrapper>
        )}

        <BodyBlocks body={content.body} />
      </div>
    </Layout>
  )
}

export default NewsPage
