import React, { useContext } from "react"
// import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import StartHeroHeader from "../../../../components/modules/StartHeroHeader"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

import { NavContext } from "@yumgmbh/gatsby-plugin-sb-nav/src/context/NavContext"

const StartPage = ({ content, name }) => {
  const { navData } = useContext(NavContext) || { navData: {} }
  // const { story } = data

  // const content = JSON.parse(story.content)

  return (
    <Layout showHeader={false}>
      <SEO content={content} name={name} />
      <StartHeroHeader
        navData={navData?.mainNavigation?.navigation}
        block={{
          images: content.hero_images,
          text: content.intro_text,
          link: content.link,
          link_text: content.link_text,
        }}
      />
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

// export const data = graphql`
//   {
//     story: storyblokEntry(full_slug: { eq: "home" }) {
//       name
//       content
//       full_slug
//     }
//   }
// `

export default StartPage
