import React, { useState } from "react"
import { Helmet } from "react-helmet"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"
import Link from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Link"
import Richtext from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Richtext"

import logo from "../../images/kio-logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight, faBars } from "@fortawesome/pro-solid-svg-icons"

import whiteHeart from "../../images/kio-herz-weiss.svg"

const StartHeroHeader = ({ block, navData }) => {
  const [menuState, setMenuState] = useState(null)
  const [randomImageIndexState, setRandomImageIndexState] = useState(
    Math.floor(Math.random() * block.images.length)
  )

  const menuClickHandler = (state) => {
    if (menuState === state) {
      setMenuState(null)
    } else {
      setMenuState(state)
    }
  }
  return (
    <>
      {menuState !== null && (
        <Helmet
          htmlAttributes={{
            class: "overflow-hidden",
          }}
          bodyAttributes={{
            class: "theme-default font-body font-medium overflow-hidden",
          }}
        />
      )}

      <header>
        {/* Mobile-Nav Design */}
        <div className="fixed top-0 z-40 block w-full bg-white border-b border-gray-300 md:hidden">
          <div className="flex flex-row flex-wrap max-w-screen-xl px-8 mx-auto">
            {/* logo */}
            <Link className="inline-block h-8 my-3 md:h-10 md:my-5" link="/">
              <Image
                className="inline-block h-8 md:h-10"
                image={{
                  filename: logo,
                  title: "Zur KiO-Startseite",
                  alt: "KiO - Kinderhilfe Organtransplantation",
                }}
              />
            </Link>
            {/* burger-Menu */}
            <button
              tabIndex={"open"}
              className="my-auto ml-auto -mr-4 text-gray-700 cursor-pointer md:hidden"
              onClick={() => menuClickHandler("open")}
            >
              <span className="inline-block w-10 px-2 py-3 mx-2 h-14 md:py-4 md:h-16">
                <FontAwesomeIcon
                  icon={faBars}
                  size="2x"
                  className="w-auto h-full"
                />
              </span>
            </button>
            {/* navigation */}
            <nav
              className={`absolute z-50 border-t pb-4 left-0 right-0 top-full flex-col flex-no-wrap flex-1 block bg-white border-b border-gray-300 md:pb-0 md:border-none md:bg-transparent md:mt-0 md:ml-4 md:static md:flex-row md:justify-end w-full md:flex ${
                menuState === "open" ? "" : "hidden"
              }`}
            >
              {/* main-navigation */}
              {navData?.map((item, index) => (
                <Link
                  link={item.link}
                  key={index}
                  className="flex w-full py-4 font-semibold cursor-pointer px-grid md:block md:py-7 md:px-4 hover:text-primary md:border-none md:bg-transparent md:w-auto focus:outline-none"
                >
                  {index + 1}. {item.title}
                </Link>
              ))}
              <Link
                link="/hilfen/spenden"
                className="my-auto ml-4 btn btn-primary"
              >
                Spenden
              </Link>
            </nav>
          </div>
        </div>

        {/* Desktop Design */}
        <div className="relative overflow-hidden md:h-[80vh] mt-14 md:mt-0">
          <div className="overflow-hidden h-[60vh] md:h-[80vh]">
            <Image
              image={block.images[randomImageIndexState]}
              title={block.title}
              alt={block.alt}
              className="object-cover object-center w-full h-full"
            />
          </div>
          <div className="inset-0 md:absolute ">
            <div className="relative h-full max-w-screen-xl mx-auto md:px-8">
              <div className="flex-row hidden text-white md:flex">
                <Link className="inline-block px-4 pt-6 pb-4 bg-white" link="/">
                  <Image
                    className="inline-block h-20"
                    image={{
                      filename: logo,
                      title: "Zur KiO-Startseite",
                      alt: "KiO - Kinderhilfe Organtransplantation",
                    }}
                  />
                </Link>
                <div className="inline-block px-4 pt-8 mt-5 text-lg text-shadow">
                  <span className="block font-bold">
                    Die Kinderhilfe Organtransplantation
                  </span>
                  <span>unterstützt Kinder und ihre Familien in Not.</span>
                </div>
              </div>
              <div className="bottom-0 right-0 w-full px-8 py-4 md:mt-0 md:absolute md:mb-24 md:w-1/3 md:px-4 bg-secondary md:bg-transparent">
                <h1 className="inline-block text-2xl text-white origin-bottom-left transform md:px-4 md:py-2 md:bg-gray-500 md:-rotate-12">
                  <span className="underline text-primary">Jede</span> Spende
                  hilft!
                </h1>
                <div className="md:text-shadow">
                  <Richtext className="mt-4 text-white" text={block.text} />
                </div>
                <Link link={block.link} className="mt-4 btn btn-primary">
                  {block.link_text}
                  <Image
                    className="inline-block h-5 pb-1 ml-2"
                    image={{ filename: whiteHeart, alt: "KiO hilft" }}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="relative hidden -mt-16 md:block block-w-container">
          <nav className="flex flex-row flex-wrap -mx-grid">
            {navData?.map((item, index) => (
              <div key={`nav-${index}`} className="w-full md:w-1/3 px-grid">
                <Link
                  link={item.link}
                  className="block w-full h-full px-4 py-2 text-white md:py-4 bg-secondary hover:bg-secondary-hover"
                >
                  <div className="flex flex-row">
                    <span className="block mr-4 text-4xl opacity-60">
                      {index + 1}.
                    </span>
                    <div className="">
                      <span className="text-lg font-bold md:block">
                        {item.title}
                      </span>
                      <span className="hidden mt-2 md:block opacity-60">
                        {item.description}
                      </span>
                      <span className="inline-block ml-4 md:mt-2 md:ml-0">
                        Weiter
                        <FontAwesomeIcon
                          icon={faArrowRight}
                          size="1x"
                          className="ml-2"
                        />
                      </span>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </nav>
        </div>
      </header>
    </>
  )
}

export default StartHeroHeader
