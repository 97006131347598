import React from "react"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import HeroBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/HeroBlock"
import SubNavBlock from "@yumgmbh/gatsby-theme-yum-components/src/components/blocks/SubNavBlock"
import Breadcrumbs from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Breadcrumbs"

const MicrositeSubPage = ({
  content,
  subNavItems,
  parentContent,
  breadcrumbs,
}) => {
  return (
    <Layout>
      <Breadcrumbs breadcrumbs={breadcrumbs}></Breadcrumbs>
      <HeroBlock
        block={{
          image: parentContent.image,
          kicker: parentContent.kicker,
          headline: parentContent.headline,
          subheadline: parentContent.subheadline,
          logo: parentContent.logo,
          buttons: parentContent.buttons,
        }}
      />
      <SubNavBlock block={{ subNavItems: subNavItems }} />
      <BodyBlocks body={content.body} />
    </Layout>
  )
}

export default MicrositeSubPage
